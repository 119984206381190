import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "milligram/dist/milligram.min.css";

// Determine the application.
const accountHolder = "account-holder";
const pathPieces = window.location.pathname.split("/");
let App = undefined;
if (pathPieces[0] === accountHolder) {
  App = React.lazy(() => import("./account-holder/app"));
} else if (pathPieces.length > 1 && pathPieces[1] === accountHolder) {
  App = React.lazy(() => import("./account-holder/app"));
} else {
  App = React.lazy(() => import("./merchant/app"));
}

const rootElement = document.getElementById("root");
const rootComponent = ReactDOM.createRoot(rootElement);
rootComponent.render(
  <App />
);